.single_article{
  width: 80%;
  height: auto;
  max-height: 70vh;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
  color: black;
display: flex;
flex-direction: column;
    justify-content: center;
}

.single_article .article_text{
    word-wrap: break-word;
    height: auto;
    max-height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
}