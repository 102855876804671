.article{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 50px;
}

.article-title{
    font-weight: 600;
    padding-bottom: 10px;
}
.article-short-desc{
    display: flex;
    flex-direction: column;
}
.article-text{
    padding-bottom: 10px;
}
.article_btn{
    width: 20%;
    margin-top: 10px;
    padding: 5px;
}
.article_card {
    width: 90%;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid powderblue;
    border-radius: 10px;
}