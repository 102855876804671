.create_post{
    width: 100%;
    /* height: calc(100vh - 80px); */
    display: grid;
    place-items: center;
}
.cp_container{
    width: 500px;
    height: auto;
    padding: 20px;
    background-color: black;
    border-radius: 12px;
    color: white;
    display: flex;
    flex-direction: column;
}
.cp_container label {
    font-size: 25px;
}

.cp_container .cp_inputgp {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.cp_inputgp input,
.cp_inputgp textarea {
    font-size: 18px;
    border: none;
    border-radius: 2px;
    padding: 5px;
}

.cp_inputgp input {
    height: 40px;
}

.cp_inputgp textarea {
    height: 150px;
}

.cp_container button {
    margin-top: 20px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
}

input,
textarea {
    margin-top: 5px;
}