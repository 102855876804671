.articleAdmin{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.articleContainer{
    border: 1px solid white;
    border-radius: 10px;
    width: 65%;
    height: 85%;
    display: flex;
    overflow: hidden;
}