.articles{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.articles-heading{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}
.articles-wrapper{
    display: flex;
    flex-direction: row;
    width: 90%;
}
.articles-featured{
    flex: 6;
}
.articles-of-interest{
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 50px;
    border: 1px solid gray;
    margin: 20px;
}
.articles-i-header{
    font-weight: 600;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.i-hr-div{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.i-hr{
    width: 100%;
    padding-bottom: 10px;
}
.article-links-wrapper{
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
}
.interest-row{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-bottom: 5px;
    width: 100%;
    background-color: whitesmoke;
}
.article-number{
    padding-right: 20px;
}

@media screen and (max-width: 480px){
    .articles {
        height: fit-content;
    }
    .articles-wrapper{
        height: fit-content;
        flex-direction: column;
    }
   
}