.formContainer{
    background-color: whitesmoke;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formWrapper{
    background-color: white;
    padding: 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input {
    padding: 15px;
    border: none;
    border-bottom: 1px solid black;
    width: 250px;
}

.logo{
    color: black;
    font-weight: bold;
    font-size: 24px;
}

.title{
    color: black;
    font-size: 12px;
}

.button{
    background-color: #222;
    color: white;
    padding: 10px;
    font-weight: bold;
    border: none;
    cursor: pointer;
}