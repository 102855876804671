.s {
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.s-heading{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}
.s-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60%;
    width: 100%;
}
.s-left{
    flex: 1;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.s-right{
    flex: 1;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.s-item{
    display: flex;
    font-display: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.s-icon{
    padding-right: 5px;
}
.s-items-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

@media screen and (max-width: 480px){
    .s{
        height: fit-content;
        width: 90%;
        padding: 20px;
    }
    .s-wrapper{
        flex-direction: column;
        height: fit-content;
        padding: 20px;
    }
}