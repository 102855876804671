.sidebar{
    flex: 1;
    border-right: 1px solid lightgray;
    padding: 50px 30px;
}
.sidebar_item{
    margin: 5px 0px;
    padding: 10px;
    cursor: pointer;
}
.sidebar_item:hover{
    background-color: aliceblue;
    color: black;
}
.logout{
    margin: 5px 0px;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: lightcoral;
}