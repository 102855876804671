.f{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40vh;
    /* background-color: whitesmoke; */
}
.f-wrapper{
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
}
.f-left-title{
    font-weight: 600;
    padding-bottom: 5px;
}
.f-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.f-row-address{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.f-row-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
}
.f-address{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.f-row-text{
    display: flex;
    align-items: center;
    justify-content: center;
}

.f-email-link{
    text-decoration: none;
}

.f-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.f-center-title{
    font-weight: 600;
    font-size: 36px;
    padding: 10px;
}
.f-center-socials{
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
}
.f-social-icon{
    cursor: pointer;
}
.f-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.f-right-title{
    font-weight: 600;
    padding-bottom: 5px;
}
.f-right-col{
    padding: 10px;
}
.f-bottom-row{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 480px){
    .f{
        height: fit-content;
        
    }

    .f-wrapper{
        height: fit-content;
        flex-direction: column;
        align-items: center;
    }
    .f-left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .f-center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .f-center-text{
        display: none;
    }
    .f-center-socials{
        width: 50%
    }

    .f-right{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
