.section-separator{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
}
.s-hr{
    width: 60%;
}