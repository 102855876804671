.l {
    min-height: 40vh;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.l-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.l-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.l-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    padding: 0 3rem;
}

.l-row{
    margin: 10px 5px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
}
.l-row-title {
    font-weight: 600;
    padding-bottom: 10px;
}

.l-email-link{
    text-decoration: none;
}

@media screen and (max-width: 820px){
    .l-wrapper {
        padding: 30px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 60%;
    }

    .l-row {
        text-align: center;
        align-items: center;
        width: 100%;
    }
    .l-row:hover {
        background-color: aliceblue;
    }
}