.main{
    flex: 2;
}

.main_title{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
